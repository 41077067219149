import { gql, useMutation } from "@apollo/client";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { AccountItem, AccountItemLine } from "./AccountItem.js";
import DarkModeToggle from "./DarkModeToggle.js";
import { useLocalStorage } from "../../hooks/use-local-storage.js";
import ToggleSwitch from "../common/buttons/ToggleSwitch";

const EDIT_ACCOUNT_PREFERENCES = gql`
  mutation editAccountPreferences(
    $shouldHidePresetTemplates: Boolean
  ) {
    editAccountPreferences(
      shouldHidePresetTemplates: $shouldHidePresetTemplates
    ) {
      ok
    }
  }
`;

export default function PreferencesSettings({ data, refetch }) {
  const [shouldHidePresetTemplates, setShouldHidePresetTemplates] =
    useState(false);

  const [editAccountPreferences] = useMutation(
    EDIT_ACCOUNT_PREFERENCES,
  );

  useEffect(() => {
    setShouldHidePresetTemplates(
      data?.user?.account?.shouldHidePresetTemplates,
    );
  }, [data]);

  return (
    <div id="preferences">
      <AccountItem
        title="Preferences"
        icon={
          <FontAwesomeIcon icon={faToolbox} size="lg" color="gray" />
        }
      >
        <div className="flex flex-col flex-1 w-full space-y-4 divide-y dark:divide-gray-700">
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Appearance:</strong> Using Scribenote late at
              night? Turn on dark mode and your eyes will thank you!
              👀
            </div>
            <DarkModeToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Capitalize SOAP Titles:</strong> When you copy
              an entire SOAP, this setting will determine if the S, O,
              A, and P titles are capitalized for readability.
            </div>
            <CapitalFormatToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Hide Preset Templates:</strong> This setting
              will turn off the default Scribenote templates.
            </div>
            <div>
              <ToggleSwitch
                toggleState={shouldHidePresetTemplates}
                setToggleState={setShouldHidePresetTemplates}
                toggleCallback={() => {
                  editAccountPreferences({
                    variables: {
                      shouldHidePresetTemplates:
                        // state has already changed, so save the opposite
                        !shouldHidePresetTemplates,
                    },
                  });

                  refetch();
                }}
              />
            </div>
          </AccountItemLine>
        </div>
      </AccountItem>
    </div>
  );
}

function CapitalFormatToggle() {
  const [
    shouldUseCapitalSubtitlesCopy,
    setShouldUseCapitalSubtitlesCopy,
  ] = useLocalStorage("should-use-capital-subtitles-copy");

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <p>Don't Capitalize</p>
      <ToggleSwitch
        toggleState={shouldUseCapitalSubtitlesCopy}
        setToggleState={setShouldUseCapitalSubtitlesCopy}
      />
      <p>Capitalize</p>
    </div>
  );
}
