import { createContext, useContext } from "react";

import { usePorterMigrationFlag } from "../hooks/use-porter-migration-flag";

const ScribenoteGlobalContext = createContext();

export function ScribenoteGlobalContextProvider({ children }) {
  usePorterMigrationFlag();

  return (
    <ScribenoteGlobalContext.Provider>
      {children}
    </ScribenoteGlobalContext.Provider>
  );
}

export function useScribenoteGlobalContext() {
  return useContext(ScribenoteGlobalContext);
}
