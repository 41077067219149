import React from "react";
import { useNavigate } from "react-router-dom";

import PricingCard from "./PricingCard.js";
import {
  UNLIMITED_PRICE_ID_MONTHLY,
  UNLIMITED_PRICE_ID_YEARLY,
  UNLIMITED_PRICING_MONTHLY,
  UNLIMITED_PRICING_YEARLY,
  UNLIMITED_PRODUCT_NAME,
} from "../../constants";
import { CardContent } from "./PricingCardContents/CardContent.js";

export default function UpgradePlan() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="mt-10">
        <h3 className="text-center mb-10">
          Which plan would you like to subscribe to?
        </h3>
        <div className="flex flex-col items-center justify-between">
          <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-10">
            <PricingCard
              shouldShowYearlyPricing={false}
              price={UNLIMITED_PRICING_MONTHLY}
              planName="Monthly"
              onClick={() => {
                navigate(
                  `/checkout/${UNLIMITED_PRODUCT_NAME}/${UNLIMITED_PRICE_ID_MONTHLY}`,
                );
              }}
            >
              <CardContent />
            </PricingCard>
            <PricingCard
              shouldShowYearlyPricing={true}
              price={UNLIMITED_PRICING_YEARLY}
              planName="Yearly"
              onClick={() => {
                navigate(
                  `/checkout/${UNLIMITED_PRODUCT_NAME}/${UNLIMITED_PRICE_ID_YEARLY}`,
                );
              }}
            >
              <CardContent />
            </PricingCard>
          </div>
          {/* <h5 className="text-sm text-gray-300 dark:text-gray-600 mt-8">
            *This pricing is subject to change
          </h5> */}
          <button
            className="text-xs md:text-sm text-indigo-500 underline mt-10"
            onClick={() =>
              (window.location = "mailto:support@scribenote.com")
            }
          >
            Looking for plans for more than one user? Click here to
            contact us.
          </button>
        </div>
      </div>
    </div>
  );
}
